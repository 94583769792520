
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { UserInfo } from '@/types'
export default defineComponent({
  name: 'UserProfile',
  setup () {
    const store = useStore()
    const userInfo = computed(() => {
      return store.getters.userInfo as UserInfo
    })
    return { userInfo }
  }
})
